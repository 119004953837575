import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="footer--content container">
        <h1 className="title title--small">Know where to turn</h1>
        <div className="footer--content__logos">
          <div>
            <img
              src={require("../images/way-to-quit-logo.png")}
              alt="Way to Quit Logo"
            />
            <p>
              Looking to quit tobacco? Access tools to quit your way at{" "}
              <a href="http://waytoquit.org" target="_blank">
                waytoquit.org
              </a>
              .
            </p>
          </div>
          <div>
            <img src={require("../images/trevor.png")} alt="Way to Quit Logo" />
            <p>
              If you’re LGBTQ+ and thinking about suicide, get immediate help{" "}
              <a href="http://thetrevorproject.org" target="_blank">
                thetrevorproject.org
              </a>
              .
            </p>
          </div>
          <div>
            <img
              src={require("../images/211-logo.png")}
              alt="Way to Quit Logo"
              style={{ maxHeight: 100 }}
            />
            <p>
              For help with local food, employment, housing, and human
              trafficking issues, visit{" "}
              <a href="http://211.org/" target="_blank">
                211.org
              </a>
              .
            </p>
          </div>
          <div>
            <img
              src={require("../images/tobacco-talk-logo.png")}
              alt="Way to Quit Logo"
            />
            <p>
              The next generation of tobacco products have changed. Take action
              to reduce youth access to nicotine and tobacco products at{" "}
              <a href="http://thetobaccotalk.org" target="_blank">
                thetobaccotalk.org
              </a>
              .
            </p>
          </div>
          <div>
            <img
              src={require("../images/dept-health-logo.png")}
              alt="Way to Quit Logo"
            />
            <p>
              Find workshops, education programs, and health screenings at{" "}
              <a href="https://livingwell.utah.gov" target="_blank">
                livingwell.utah.gov
              </a>
              .
            </p>
          </div>
          <div>
            <img
              src={require("../images/safeut-logo.png")}
              alt="Way to Quit Logo"
              style={{ maxHeight: 150 }}
            />
            <p>
              For real-time crisis intervention for youth or to submit a
              confidential tip, visit{" "}
              <a href="https://safeut.med.utah.edu" target="_blank">
                safeut.med.utah.edu/
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div className="copyright">
        <img
          src={require("../images/arrow-up.png")}
          alt="Go to top"
          className="go-top"
          onClick={() => {
            window.scrollTo({ behavior: "smooth", top: 0 })
          }}
        />
        Copyright © {new Date().getFullYear()}, Utah Department of Health
      </div>
    </footer>
  )
}

export default Footer
